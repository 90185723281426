import React, { Component, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './scss/style.scss';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import moment from 'moment';
import 'moment/locale/fr';
import { toast } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

export const stripePromise = loadStripe(
  'pk_test_51NAuNTFnV1sRkwn0ONC8Gyc0vpDFbHwR0YY3CQsBoTWEoCGi44cr37cLgCtsYfmRYQFm1OZQPJ5R9hycSlna5Y6k00rdQXHgq3',
);
// Pages
const Auth = React.lazy(() => import('./views/pages/auth/Auth'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const queryClient = new QueryClient();
moment.locale('fr');

const App = () => {
  const [isTokenPresent, setIsTokenPresent] = useState(false);

  useEffect(() => {
    // Votre logique pour vérifier si un token est présent dans le localStorage
    const token = localStorage.getItem('token');
    setIsTokenPresent(token !== '');
  }, []);

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={loading}>
          <Routes>
            {true ? (
              <>
                <Route exact path="/authentification" name="Login Page" element={<Auth />} />
                <Route exact path="/register" name="Register Page" element={<Register />} />
                <Route exact path="/404" name="Page 404" element={<Page404 />} />
                <Route exact path="/500" name="Page 500" element={<Page500 />} />
                <Route path="*" name="Home" element={<DefaultLayout />} />
              </>
            ) : (
              <>
                <Route exact path="/authentification" name="Login Page" element={<Auth />} />
                <Route exact path="/register" name="Register Page" element={<Register />} />
                <Route path="*" element={<Navigate to="/authentification" />} />
              </>
            )}
          </Routes>
        </Suspense>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
